<template>
  <div v-if="shops" class="card tabs shadow-sm">
    <input
      type="radio"
      id="tab1"
      name="tab-control"
      :checked="isActiveRegister === true"
    />
    <input
      type="radio"
      id="tab2"
      name="tab-control"
      :checked="isActiveLoyaltyPoint === true"
    />
    <input
      type="radio"
      id="tab3"
      name="tab-control"
      :checked="isActiveRewardCard === true"
    />
    <input
      type="radio"
      id="tab4"
      name="tab-control"
      :checked="isActiveLineNotify === true"
    />
    <input
      type="radio"
      id="tab5"
      name="tab-control"
      :checked="isActiveContact === true"
    />
    <input
      type="radio"
      id="tab6"
      name="tab-control"
      :checked="isActivePoster === true"
    />
    <ul class="ulbar font-weight-normal">
      <li :title="$t('shopRegister')">
        <label for="tab1" role="button">
          <em class="fas fa-store d-lg-none d-md-none"></em>
          <br /><span> {{ $t('shopRegister') }}</span>
        </label>
      </li>
      <li :title="$t('settingRoyaltyPoint')">
        <label for="tab2" role="button">
          <em class="fas fa-donate d-lg-none d-md-none"></em>
          <br /><span> {{ $t('settingRoyaltyPoint') }}</span>
        </label>
      </li>
      <li :title="$t('cardSetting')">
        <label for="tab3" role="button">
          <em class="fas fa-credit-card  d-lg-none d-md-none"></em>
          <br /><span> {{ $t('cardSetting') }}</span>
        </label>
      </li>
      <li :title="$t('setUpAutoNotify')">
        <label for="tab4" role="button">
          <em class="fas fa-bell d-lg-none d-md-none"></em>
          <br /><span> {{ $t('setUpAutoNotify') }}</span>
        </label>
      </li>
      <li :title="$t('contact')">
        <label for="tab5" role="button">
          <em class="fas fa-address-book d-lg-none d-md-none"></em>
          <br /><span> {{ $t('contact') }}</span>
        </label>
      </li>
      <li :title="$t('businessPoster')">
        <label for="tab6" role="button">
          <em class="fas fa-photo-video d-lg-none d-md-none"></em>
          <br /><span> {{ $t('businessPoster') }}</span>
        </label>
      </li>
    </ul>

    <div class="slider"><div class="indicator"></div></div>

    <div class="content">
      <section>
        <shop-register></shop-register>
      </section>
      <section>
        <point-config></point-config>
      </section>
      <section>
        <reward-card></reward-card>
      </section>
      <section>
        <line-notify></line-notify>
      </section>
      <section>
        <contact></contact>
      </section>
      <section>
        <poster></poster>
      </section>
    </div>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ShopRegister from '../Register'
import Contact from './Contact'
import PointConfig from '../point/Config'
import RewardCard from '../card/shop/Settings'
import LineNotify from './LineNotify'
import Poster from './Poster'
import NoShop from '@/containers/NoShop.vue'
import '@/util/tabs.css'

export default {
  components: {
    ShopRegister,
    PointConfig,
    RewardCard,
    LineNotify,
    Contact,
    Poster,
    NoShop,
  },
  data() {
    return {
      checkStatus: true,
      isActiveRegister: false,
      isActiveLoyaltyPoint: false,
      isActiveRewardCard: false,
      isActiveContact: false,
      isActiveLineNotify: false,
      isActivePoster: false,
    }
  },
  computed: {
    ...mapGetters(['shops']),
  },
  mounted() {
    if (this.$route.hash === '' || this.$route.hash === '#register') {
      this.isActiveRegister = true
    } else if (this.$route.hash === '#loyaltypoint') {
      this.isActiveLoyaltyPoint = true
    } else if (this.$route.hash === '#rewardcard') {
      this.isActiveRewardCard = true
    } else if (this.$route.hash === '#contact') {
      this.isActiveContact = true
    } else if (this.$route.hash === '#linenotify') {
      this.isActiveLineNotify = true
    } else if (this.$route.hash === '#poster') {
      this.isActivePoster = true
    }
  },
}
</script>
