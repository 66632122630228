<template>
  <div>
    <CRow>
      <CCol md="12" class="text-black">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol md="12" class="text-center">
                <h2 class="font-weight-normal text-dark">{{ $t('settingRoyaltyPoint') }}</h2>
                <h6 class="text-description">
                  {{ $t('setConversionRate') }}
                </h6>
              </CCol>
            </CRow>
            <hr />
            <CAlert color="success" v-if="isSuccess === true" :show="showtime">
              {{ $t('saveLoyaltyPointSettingSuccess') }}
            </CAlert>
            <CAlert color="danger" v-if="isSuccess === false" :show="showtime">
              {{ $t('somethingWentWrong') }}
            </CAlert>

            <div v-if="showRegister === true" class="text-center">
              <h5>{{ $t('noRegisterLineOA') }}</h5>
              <p>
                {{ $t('youCanRegisterAt') }}
                <strong>{{ $t('shopRegister') }}</strong>
                {{ $t('orCheckOutAt') }}
                <router-link :to="'/manual'" class="underline">
                  {{ $t('systemUserManual') }}
                </router-link>
              </p>
            </div>

            <div v-else-if="showRegister === false">
              <table style="width:100%" aria-describedby="">
                <tr class="font-weight-normal h6">
                  <th scope="col" style="width:6%">
                    <h5 class="text-left">
                      <CIcon class="text-white" name="cil-settings" size="3xl"
                        style="background-color:#3abc98;padding:10px;" />
                    </h5>
                  </th>
                  <td>
                    <h6 class="text-left font-weight-normal text-dark mt-2">{{ $t('usabilityStatus') }}</h6>
                  </td>
                  <td v-if="isStatus === true" class="text-right text-success">
                    {{ $t('enable') }}
                  </td>
                  <td v-if="isStatus === false" class="text-right text-danger">
                    {{ $t('disable') }}
                  </td>
                  <td style="width:5%" class="text-right" @click="statusPopup">
                    <CSwitch v-model="isStatus" color="success" :value="isStatus" :checked.sync="isStatus" />
                  </td>
                </tr>
              </table>

              <p class="text-danger font-weight-normal">{{ amountAlert }}</p>
              <p class="text-success font-weight-normal">
                {{ message }}
              </p>
              <div v-if="edit === true">
                <CRow>
                  <CCol md="7">
                    <p class="font-weight-normal">
                      {{ $t('calculationFormat') }}
                      <CIcon name="cil-info" v-c-popover="{
                        header: $t('shopDoNotSetFormmat'),
                        content: this.$i18n.t('shopDoNotSetFormmatDialog'),
                      }"></CIcon>
                    </p>
                  </CCol>
                  <CCol md="2">
                    <input type="checkbox" v-model="isRounded" true-value="true" false-value="false" disabled />
                    {{ $t('roundingValue') }}
                  </CCol>
                  <CCol md="2">
                    <input type="checkbox" v-model="isRounded" true-value="false" false-value="true" disabled />
                    {{ $t('unRoundingValue') }}
                  </CCol>
                </CRow>
                <p class="font-weight-normal">
                  {{ $t('settingRateLoyaltyPotint') }}
                </p>
                <CRow>
                  <CInput class="col-md-5" v-model.number="amount" type="text" :placeholder="$t('inputMoneyAmount')"
                    v-if="isStatus === true" disabled>
                    <template #prepend-content>
                      <span class="text-danger font-weight-normal">*</span>
                      <span class="text-dark font-weight-normal">
                        {{ $t('amountMoney') }}
                      </span>
                    </template>
                  </CInput>
                  <CInput class="col-md-5" v-model.number="amount" type="text" :placeholder="$t('amountMoney')"
                    v-if="isStatus === false" disabled>
                    <template #prepend-content>
                      <span class="text-danger font-weight-normal">*</span>
                      <span class="text-black font-weight-normal">
                        {{ $t('amountMoney') }}
                      </span>
                    </template>
                  </CInput>
                  <div class="pt-1 col-md-2 text-center">
                    <CIcon name="cil-arrow-right" />
                  </div>
                  <div class="col-md-5 ">
                    <CInput class="text-dark" type="text" value="1" style="text-align:right;" disabled>
                      <template #append-content>
                        <span class="text-black font-weight-normal">
                          {{ $t('point') }}
                        </span>
                      </template>
                    </CInput>
                  </div>
                </CRow>
                <br />
                <CButton block color="info" class=" text-white" v-on:click="editHandlePopup" v-if="isStatus === true">
                  {{ $t('setupInformation') }}
                </CButton>
              </div>
              <div v-else-if="edit === false">
                <p class="font-weight-normal text-danger" v-if="editMessage !== ''">
                  <CIcon name="cil-warning"></CIcon>
                  {{ editMessage }}
                </p>
                <CRow>
                  <CCol md="7">
                    <p class="font-weight-normal">
                      {{ $t('calculationFormat') }}
                      <CIcon name="cil-info" v-c-popover="{
                        header: $t('shopDoNotSetFormmat'),
                        content: $t('shopDoNotSetFormmatDialog'),
                      }"></CIcon>
                    </p>
                  </CCol>
                  <CCol md="2">
                    <input type="checkbox" v-model="isRounded" true-value="true" false-value="false" />
                    ปัดเศษ
                  </CCol>
                  <CCol md="2">
                    <input type="checkbox" v-model="isRounded" true-value="false" false-value="true" />
                    ไม่ปัดเศษ
                  </CCol>
                </CRow>
                <p class="font-weight-normal">
                  {{ $t('settingRateLoyaltyPotint') }}
                </p>
                <CRow>
                  <CInput class="col-md-5" v-model.number="amount" type="text" placeholder="กรอกจำนวนเงิน"
                    v-if="isStatus === true">
                    <template #prepend-content>
                      <span class="text-danger font-weight-normal">*</span>
                      <span class="text-dark font-weight-normal">
                        {{ $t('amountMoney') }}
                      </span>
                    </template>
                  </CInput>
                  <CInput class="col-md-5" v-model.number="amount" type="text" placeholder="กรอกจำนวนเงิน"
                    v-if="isStatus === false" disabled>
                    <template #prepend-content>
                      <span class="text-danger font-weight-normal">*</span>
                      <span class="text-dark font-weight-normal">
                        {{ $t('amountMoney') }}
                      </span>
                    </template>
                  </CInput>
                  <div class="pt-1 col-md-2 text-center">
                    <CIcon name="cil-arrow-right" />
                  </div>
                  <div class="col-md-5 ">
                    <CInput class="text-dark" type="text" value="1" style="text-align:right;" disabled>
                      <template #append-content>
                        <span class="text-dark font-weight-normal">
                          {{ $t('points') }}
                        </span>
                      </template>
                    </CInput>
                  </div>
                </CRow>
                <br />
                <div v-if="isStatus === true">
                  <CButton v-if="loadingButton === true" color="success" class="btn-block" square @click="saveConfig">
                    {{ $t('save') }}
                  </CButton>
                  <CButton v-else-if="loadingButton === false" color="success" class="btn-block" square disabled>
                    <CSpinner color="white" size="sm" /> {{ $t('save') }}
                  </CButton>
                </div>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CModal :show.sync="popupModal" :footer="footer" centered class="text-center text-black" :title="$t('disable')"
        color="danger">
        <h4>{{ $t('confirmToSuspenPointAccomulate') }}</h4>
        <h6>
          {{ $t('suspenPointDialog') }}
        </h6>

        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="4">
              <CButton color="danger" v-on:click="saveConfig" block>
                {{ $t('disable') }}
              </CButton>
            </CCol>
            <CCol md="4">
              <CButton color="dark" @click="cancel()" block>
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/services/crm'

export default {
  data() {
    return {
      isStatus: false,
      isSuccess: '',
      amount: '',
      footer: '',
      popupModal: false,
      disabled: '',
      message: '',
      amountAlert: '',
      edit: '',
      showtime: 2,
      loadingButton: true,
      showRegister: '',
      isRounded: 'true',
      editMessage: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
  },
  created() {
    this.getDetail()
  },
  methods: {
    registerShow() {
      if (this.shops.length !== 0) {
        if (
          this.shops[0].isLoyaltyEnabled === undefined ||
          this.shops[0].isLoyaltyEnabled === null
        ) {
          this.showRegister = true
        } else {
          this.showRegister = false
        }
      }
    },
    statusPopup() {
      if (this.isStatus === true) {
        this.popupModal = true
        this.message = ''
        this.isSuccess = ''
      } else if (this.isStatus === false) {
        this.message =
          this.$i18n.t('enablePointDialog')
        this.isSuccess = ''
        this.edit = false
      }
    },
    editHandlePopup() {
      this.edit = false
      this.editMessage = this.$i18n.t('settingPointDialogWarning')
    },
    cancel() {
      if (this.isStatus === false) {
        this.isStatus = true
      }
      this.popupModal = false
    },
    getDetail() {
      const uid = this.uid

      axios({
        method: 'get',
        url: '/api/v1.0/' + uid + '/loyalty-point/get',
      })
        .then((response) => {
          this.data = response.data.data.documents

          if (this.data === null) {
            this.objectId = ''
            this.id = ''
          } else {
            this.objectId = this.data.objectId
            this.id = this.data.id
            this.amount = this.data.amount
            this.ratio = this.data.ratio
            this.isRounded = this.data.isRounded
          }

          if (this.isRounded === undefined) {
            this.isRounded = 'true'
          }

          if (this.ratio > 0) {
            this.isStatus = true
            this.edit = true
          } else if (this.ratio <= 0) {
            this.isStatus = false
            this.edit = false
          }

          this.registerShow()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveConfig() {
      this.loadingButton = false
      const uid = this.uid

      let shops = []
      let shop = {}
      let ratio = 0
      let isRounded = true

      for (let shopdata of this.shops) {
        shop = {
          id: shopdata.id,
          objectId: shopdata.objectId,
          shopName: shopdata.shopName,
          branchName: shopdata.branchName,
        }
        shops.push(shop)
      }

      if (this.isStatus === true) {
        ratio = 1 / Number(this.amount)
      } else if (this.isStatus === false) {
        ratio = 0
      }

      if (this.isRounded === 'true') {
        isRounded = true
      } else if (this.isRounded === 'false') {
        isRounded = false
      }

      const data = {
        objectId: this.objectId,
        id: this.id,
        uid: uid,
        shops: shops,
        amount: this.amount,
        ratio: ratio,
        isRounded: isRounded,
      }

      if (this.amount === '') {
        this.amountAlert = this.$i18n.t('validateInput')
      } else {
        axios({
          method: 'post',
          url: '/api/v1.0/' + uid + '/loyalty-point/config/save',
          data: data,
        })
          .then((response) => {
            this.saveConfigHistory(data)
          })
          .catch((error) => {
            console.log(error)
            this.isSuccess = false
          })
      }
    },
    saveConfigHistory(data) {
      //keyword: config/setting
      data.isLoyaltyPointEnabled = this.isStatus

      axios({
        method: 'post',
        url: '/api/v1.0/' + data.uid + '/loyalty-point/history/save',
        data: data,
      })
        .then((response) => {
          this.isSuccess = true
          this.popupModal = false
          this.amountAlert = ''
          this.edit = true
          this.loadingButton = true
          this.message = ''
          this.editMessage = ''
        })
        .catch((error) => {
          console.log(error)
          this.isSuccess = false
        })
    },
  },
}
</script>
