<template>
  <CRow class="d-flex justify-content-center">
    <CCol md="12" class="text-center text-black">
      <CCard>
        <CCardBody>
          <CRow>
            <CCol md="12" class="text-center">
              <h2 class="font-weight-normal text-dark">{{ $t('businessPoster') }}</h2>
              <h6 class="text-description">
                {{ $t('businessPosterDialog') }}
              </h6>
            </CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol md="6">
              <h6 class="font-weight-normal text-left text-dark">
                {{ $t('uploadQRCodeFile') }}
              </h6>
              <span style="font-size:12px;" class="text-description">
                {{ $t('QRAbleToDownload') }}
                <a href="https://account.line.biz/login">
                  {{ $t('here') }}
                </a>
              </span>
              <br />
              <CJumbotron
                v-if="qrcode !== ''"
                color="white"
                border-color="grey-200"
              >
                <a
                  class="remove-image"
                  style="display: inline;"
                  @click="changeQRCode"
                >
                  &#215;
                </a>
                <img alt="" :src="qrcode" width="50%" />
              </CJumbotron>
              <CJumbotron
                v-else
                color="white"
                border-color="grey-200"
                class="col-md-12 mx-auto"
              >
                <div class="custom-file ">
         
                  <label
                    class="custom-file text-center text-description"
                    style="font-size:18px; "
                  >
                  
                    <span class="text-grey text-center ">
                      <CIcon name="cil-cloud-upload" size="xl" />
                      {{ $t('uploadQR') }}
                    </span>
                    <input
                    style="border-color:none;visibility: hidden;"
                    type="file"
                    class="text-black form-control "
                    @change="onFileSelect"
                    required
                  />
                  </label>
                </div>
              </CJumbotron>

              <h6 class="text-left font-weight-normal text-dark">
                {{ $t('editText') }}
              </h6>
              <span style="font-size:12px;float:left;" class="text-description">
                {{ $t('editTextDialog') }}
              </span>
              <br />

              <div class="form-group row">
                <div class="col-sm-12">
                  <textarea
                    class="text-black form-control"
                    v-model="message"
                    rows="5"
                  />
                </div>
              </div>

              <br />

              <h6 class="text-left font-weight-normal text-dark">{{ $t('addContactInformation') }}</h6>
              <span style="font-size:12px;float:left;" class="text-description">
                {{ $t('addEditContactAtSetting') }}
                <label @click="contactDetail()" class="pointer">
                  {{ $t('contactInformation') }}
                  <CIcon name="cil-sync"></CIcon>
                </label>
              </span>

              <br />
              <div class="row" style="width: 100%;">
                <label class="col-sm-12 col-form-label text-left text-grey">
                  <input type="checkbox" v-model="isTel" value="true" />
                  {{ $t('tel') }}
                </label>
                <label class="col-sm-12 col-form-label text-left text-grey">
                  <input type="checkbox" v-model="isWebsite" value="true" />
                  {{ $t('website') }}
                </label>
                <label class="col-sm-12 col-form-label text-left text-grey">
                  <input type="checkbox" v-model="isFacebook" value="true" />
                  Facebook
                </label>
                <label class="col-sm-12 col-form-label text-left text-grey">
                  <input type="checkbox" v-model="isLineAdd" value="true" />
                  Line@
                </label>
              </div>
              <hr />
              <CButton
              class="mb-3"
                @click="downloadPoster()"
                v-if="loadingButton === false"
                color="success"
                block
              >
                {{ $t('downloadPoster') }}
              </CButton>
              <CButton
                disabled
                v-else-if="loadingButton === true"
                color="success"
                block
              >
                <CSpinner color="white" size="sm" /> {{ $t('downloadPoster') }}
              </CButton>
            </CCol>
            <CCol md="6" class="wrp">
              <p class="text-dark font-weight-normal text-left">
                {{ $t('posterExample') }}
              </p>
              <CJumbotron
                :style="{
                  'background-size': '100% 100%',
                  'background-image': `url('https://www.img.in.th/images/716ec33abe01a3807327a087a4c9f4e7.jpg')`,
                }"
                color="white"
                border-color="grey-200"
                class="col-md-12 mx-auto"
              >
                <h1 class="text-black font-weight-normal" style="font-size:48px">
                  {{ shopName }}
                </h1>
                <h6 class="text-grey">
                  {{ $t('posterExampleDialog') }}
                </h6>
                <h6 class="text-grey">{{ $t('justAddFriend') }}</h6>
                <br />
                <img
                  v-if="qrcode === ''"
                  style="width:40%"
                  class="img-fluid"
                  :src="qrsilom"
                  alt=""
                />
                <img alt="" style="width:40%" class="img-fluid" :src="qrcode" />

                <br />
                <br />
                <h3 class="text-grey font-weight-normal">{{ $t('scan') }} QR Code</h3>
                <p class="text-grey">{{ message }}</p>
                <br />

                <p v-if="isTel == true" style="padding:10px;display: inline;">
                  <em class="fas fa-mobile-alt"></em> {{ tel }}&nbsp;
                </p>
                <p
                  v-if="isWebsite == true"
                  style="padding:10px;display: inline;"
                >
                  <em class="fas fa-laptop"></em> {{ website }}
                </p>
                <p
                  v-if="isFacebook == true"
                  style="padding:10px;display: inline;"
                >
                  <em class="fab fa-facebook-square"></em> {{ facebook }}
                </p>
                <p
                  v-if="isLineAdd == true"
                  style="padding:10px;display: inline;"
                >
                  <em class="fab fa-line"></em> {{ lineadd }}
                </p>
                <br />
                <br />

                <span>
                  Powered by
                  <img alt="" class="img-fluid" :src="logo" style="width:15%" />
                </span>
              </CJumbotron>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal :show.sync="imgModal" centered header="false" :footer="footer">
      <CRow class="justify-content-center">
        <CCard
          color="white"
          text-color="dark"
          class="text-center col-md-12"
          body-wrapper
        >
          <CCardBody color="light">
            <VueCropper
              v-show="selectedFile"
              ref="cropper"
              :src="selectedFile"
              alt="Source Image"
            >
            </VueCropper>
          </CCardBody>
        </CCard>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol col="4">
            <CButton
              v-if="loadingImg === false"
              block
              color="success"
              @click="editImg()"
            >
              {{ $t('submit') }}
            </CButton>
            <CButton
              v-else-if="loadingImg === true"
              block
              color="success"
              disabled
            >
              <CSpinner color="white" size="sm" />
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="light" @click="imgModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import crm from '@/services/crm'
import util from '@/util/print'
import report from '@/services/report'
import pos from '@/services/pos'

export default {
  components: { VueCropper },
  data() {
    return {
      isTel: '',
      isWebsite: '',
      isFacebook: '',
      isLineAdd: '',
      tel: '',
      website: '',
      facebook: '',
      lineadd: '',
      message:this.$i18n.t('posterDialog'),
      logo: '../../logo-ab.png',
      loadingButton: false,
      qrsilom: 'https://www.silompos.com/assets/images/line_qr.png',
      imgModal: false,
      selectedFile: '',
      cropedImage: '',
      footer: '',
      loadingImg: false,
      imgUrl: '',
      objectId: '',
      qrcode: '',
    }
  },
  computed: {
    ...mapGetters(['users', 'shops', 'shopObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopName() {
      if (this.shops.length > 0) {
        return this.shops[0].shopName
      } else {
        return ''
      }
    },
  },
  created() {
    this.contactDetail()
  },
  methods: {
    changeQRCode() {
      this.qrcode = ''
    },
    uploadImage(image) {
      let data = {
        image: image,
      }

      pos
        .post('/images/v1.0/upload/general/' + this.uid, data)
        .then((res) => {
          if (res.data.error.code === 0) {
            let baseUrl = res.data.data.baseUrl
            let url = res.data.data.url

            this.imgUrl = baseUrl + url
            this.qrcode = this.imgUrl

            this.updateContact()
            this.imgModal = false
          } else {
            console.log(res.data.error.message)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    editImg() {
      this.loadingImg = false
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.uploadImage(this.cropedImage)
      this.loadingImg = true
    },
    onFileSelect(e) {
      const file = e.target.files[0]

      this.imgModal = true

      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    contactDetail() {
      const uid = this.uid

      crm({
        method: 'get',
        url: 'api/v1.0/' + uid + '/contact/getbyuid',
      })
        .then((response) => {
          let documents = response.data.data.documents
          if (documents !== null) {
            this.objectId = documents.objectId
            this.tel = documents.tel
            this.website = documents.website
            this.facebook = documents.facebook
            this.lineadd = documents.lineadd
            this.qrcode = documents.qrcode
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    updateContact() {
      const uid = this.uid
      const data = {
        objectId: this.objectId,
        uid: this.uid,
        qrcode: this.imgUrl,
      }

      crm({
        method: 'post',
        url: 'api/v1.0/' + uid + '/contact/save',
        data: data,
      })
        .then((response) => {
          this.loadingImg = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    downloadPoster() {
      this.loadingButton = true
      if (this.isTel == false) {
        this.tel = ''
      }

      if (this.isWebsite == false) {
        this.website = ''
      }

      if (this.isFacebook == false) {
        this.facebook = ''
      }

      if (this.isLineAdd == false) {
        this.lineadd = ''
      }

      if (this.qrcode === '') {
        this.qrcode = this.qrsilom
      }

      let info = {
        shopName: this.shopName,
        tel: this.tel,
        website: this.website,
        facebook: this.facebook,
        lineadd: this.lineadd,
        message: this.message,
        qrcode: this.qrcode,
      }

      let html = util.exportPoster(info)

      let data = {
        ref: this.shopObjectId,
        timezone: 'Asia/Bangkok',
        html: html,
      }
      let name = 'poster' + '.pdf'

      report({
        method: 'post',
        url: '/print/v1.0/' + this.uid + '/htmltopdf/a4',
        data: data,
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        this.loadingButton = false
      })
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@300&display=swap');
@font-face {
  font-family: 'Mitr', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Mitr:wght@300&display=swap');
}

.wrp {
  font-family: 'Mitr', sans-serif;
}

.remove-image {
  display: none;
  position: absolute;
  top: 30px;
  right: 5px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #393939;
  border: 1px solid #fff;
  color: #fff;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
}
.remove-image:active {
  background: #e54e4e;
}

.pointer {
  cursor: pointer;
}
</style>
