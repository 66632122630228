<template>
  <CRow>
    <CCol col="12" class="text-black">
      <CCard>
        <CCardBody>
          <CRow>
            <CCol md="12" class="text-center">
              <h2 class="font-weight-normal text-dark">{{ $t('setUpAutoNotify') }}</h2>
              <h6 class="text-description">
                {{ $t('setUpAutoNotifyDialog') }}
              </h6>
            </CCol>
          </CRow>
          <hr />

          <div v-if="showRegister === true" class="text-center">
            <h5>{{ $t('noRegisterLineOA') }}</h5>
            <p>
              {{ $t('youCanRegisterAt') }}
              <strong>{{ $t('shopRegister') }}</strong>
              {{ $t('orCheckOutAt') }}
              <router-link :to="'/manual'" class="underline">
                {{ $t('systemUserManual') }}
              </router-link>
            </p>
          </div>

          <div v-else-if="showRegister === false">
            <table style="width:100%" aria-describedby="">
              <tr class="font-weight-normal h5">
                <th scope="col" style="width:6%">
                  <h6 class="text-left">
                    <CIcon
                      class="text-white"
                      name="cil-settings"
                      size="3xl"
                      style="background-color:#3abc98;padding:10px;"
                    />
                  </h6>
                </th>
                <td>
                  <h6 class="text-left font-weight-normal text-dark">{{ $t('usabilityStatus') }}</h6>
                </td>
                <td v-if="isStatus === true" class="text-right text-success h6">
                  {{ $t('enable') }}
                </td>
                <td v-if="isStatus === false" class="text-right text-danger h6">
                  {{ $t('disable') }}
                </td>
                <td style="width:5%" class="text-right" @click="statusPopup">
                  <CSwitch
                    v-model="isStatus"
                    color="success"
                    :value="isStatus"
                    :checked.sync="isStatus"
                  />
                </td>
              </tr>
            </table>

            <p class="text-description">
              {{ $t('lineNotifyDialog') }}
              <a @click="guide = true">
                {{ $t('manualGenerateToken') }}
              </a>
            </p>
            <CAlert v-if="isSuccess !== ''" color="success" :show="showtime">
              {{ isSuccess }}
            </CAlert>
            <p class="text-danger">
              {{ alertMessage }}
            </p>
            <p class="text-success">
              {{ message }}
            </p>

            <div v-if="edit === true">
              <table style="width:100%" aria-describedby="">
                <tr class="h5 text-left">
                  <th scope="col" class="font-weight-normal">Access Token</th>
                  <td class="font-weight-normal">
                    <input
                      class="form-control text-black"
                      :placeholder="$t('inputAccessTokenPlaceholder')"
                      v-model="token"
                    />
                  </td>
                </tr>
              </table>

              <br />
              <CButton @click="handlePopup" block color="success" class="px-4">
                {{ $t('save') }}
              </CButton>
            </div>

            <div v-else-if="edit === false">
              <table style="width:100%" aria-describedby="">
                <tr class="h5 text-left">
                  <th scope="col" class="font-weight-normal text-dark">Access Token</th>
                  <td class="font-weight-normal">
                    <input
                      class="form-control text-dark"
                      :placeholder="$t('inputAccessTokenPlaceholder')"
                      v-model="token"
                      disabled
                    />
                  </td>
                </tr>
              </table>

              <br />
              <CButton
                v-if="isStatus === true"
                @click="edit = true"
                color="info"
                class="px-4 text-white"
                block
              >
                {{ $t('edit') }}
              </CButton>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <div v-if="guide === true" class="col-md-12">
      <line-notify-guide></line-notify-guide>
    </div>
    <CModal
      :show.sync="popupModal"
      color="success"
      :title="$t('saveInformation')"
      centered
      :footer="footer"
    >
      <h4 class="text-center text-black">{{ $t('confirmSaveData') }}</h4>
      <template #footer>
        <CRow class="justify-content-center col-md-12">
          <CCol col="4">
            <CButton
              block
              color="success"
              v-if="loadingButton === true"
              @click="saveAccessToken()"
            >
              {{ $t('confirm') }}
            </CButton>
            <CButton
              block
              color="success"
              v-else-if="loadingButton === false"
              disabled
            >
              <CSpinner color="white" size="sm" />
              {{ $t('confirm') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="dark" @click="cancel()">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>

        <br />
      </template>
    </CModal>
    <CModal
      :show.sync="enabledPopup"
      color="danger"
      :title="$t('disable')"
      centered
      :footer="footer"
    >
      <h4 class="text-center text-grey">{{ $t('confirmToDisableNotify') }}</h4>
      <template #footer>
        <CRow class="justify-content-center col-md-12">
          <CCol col="4">
            <CButton
              block
              color="danger"
              v-if="loadingButton === true"
              @click="saveAccessToken()"
            >
              {{ $t('disable') }}
            </CButton>
            <CButton
              block
              color="danger"
              v-else-if="loadingButton === false"
              disabled
            >
              <CSpinner color="white" size="sm" />
              {{ $t('disable') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="dark" @click="cancel()">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
      </template>
    </CModal>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import crm from '@/services/crm'
import LineNotifyGuide from '../settings/LineNotifyGuide'

export default {
  components: {
    LineNotifyGuide,
  },
  data() {
    return {
      isStatus: false,
      popupModal: false,
      alertMessage: '',
      token: '',
      guide: false,
      loadingButton: true,
      footer: '',
      edit: '',
      message: '',
      enabledPopup: false,
      showtime: 2,
      isSuccess: '',
      objectId: '',
      id: '',
      showRegister: '',
    }
  },
  computed: {
    ...mapGetters(['shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
  },
  created() {
    this.getAccessToken()

    if (this.isStatus === true) {
      this.edit = true
    } else if (this.isStatus === false) {
      this.edit = false
    }
  },
  methods: {
    registerShow() {
      if (this.shops.length !== 0) {
        if (
          this.shops[0].isLoyaltyEnabled === undefined ||
          this.shops[0].isLoyaltyEnabled === null
        ) {
          this.showRegister = true
        } else {
          this.showRegister = false
        }
      }
    },
    handlePopup() {
      if (this.token === '') {
        this.alertMessage = this.$i18n.t('validateInput')
      } else {
        this.alertMessage = ''
        this.popupModal = true
      }
    },
    statusPopup() {
      if (this.isStatus === true) {
        //เปลี่ยนเป็นปิดใช้งาน
        this.enabledPopup = true
        this.edit = false

        this.message = ''
      } else if (this.isStatus === false) {
        //เปลี่ยนเป็นเปิดใช้งาน
        this.message = this.$i18n.t('activeNotifyDialog')

        this.edit = true
      }
    },
    cancel() {
      if (this.isStatus === false) {
        this.isStatus = true
      }
      this.popupModal = false
      this.enabledPopup = false
    },
    getAccessToken() {
      const uid = this.uid

      crm({
        method: 'get',
        url: '/api/v1.0/' + uid + '/line-notify/gettoken',
      })
        .then((response) => {
          //ถ้าไม่มี access-token ให้สถานะเริ่มต้นเป็นปิดการใช้งาน
          let documents = response.data.data.documents

          if (documents === null) {
            this.isStatus = false
          } else {
            this.isStatus = documents.isStatus
            this.token = documents.token
            this.objectId = documents.objectId
            this.id = documents.id
          }

          this.registerShow()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveAccessToken() {
      this.loadingButton = false
      const uid = this.uid

      let data = {
        objectId: this.objectId,
        id: this.id,
        uid: uid,
        shops: this.shops,
        token: this.token,
        isStatus: this.isStatus,
      }

      crm({
        method: 'post',
        url: '/api/v1.0/' + uid + '/line-notify/savetoken',
        data: data,
      })
        .then((response) => {
          this.loadingButton = true
          this.popupModal = false
          this.enabledPopup = false
          this.isSuccess = this.$i18n.t('saveSuccess')
          this.edit = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
