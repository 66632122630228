<template>
  <div v-if="shops">
    <CCard color="white" text-color="black" class="text-center" body-wrapper>
      <CAlert v-if="alert != ''" color="success" :show="showtime">
        {{ alert }}
      </CAlert>
      <CRow>
        <CCol md="12" class="text-center">
          <h2 class="font-weight-normal text-dark">{{ $t('shopRegister') }}</h2>
          <h6 class="text-description">
            <label class="text-danger">*</label>
            {{ $t('inCaseNoLineOA') }}
            <a
              href="https://developers.line.biz/en/services/messaging-api/"
              class="underline"
            >
              <span class="txt" :color="primary">
                Messaging API | LINE Developers
              </span>
            </a>
            {{ $t('orSeeDetailsAtMenu') }}
            <a href="/manual" :color="primary" class="txt"> {{ $t('systemUserManual') }} </a>
          </h6>
        </CCol>
      </CRow>
      <hr />
      <CRow>
        <CCol md="12">
          <p
            v-if="message !== ''"
            class="text-success text-left font-weight-normal"
          >
            {{ message }}
          </p>
          <table style="width:100%" aria-describedby="">
            <tr class="font-weight-normal h6 ">
              <th scope="col" style="width:6%">
                <h6 class="text-left">
                  <CIcon
                    class="text-white"
                    name="cil-settings"
                    size="3xl"
                    style="background-color:#3abc98;padding:10px;"
                  />
                </h6>
              </th>
              <td>
                <h6 class="text-left font-weight-normal text-dark mt-2">{{ $t('usabilityStatus') }}</h6>
              </td>
              <td
                v-if="isLoyaltyEnabled === true"
                class="text-right text-success"
              >
                {{ $t('enable') }}
              </td>
              <td
                v-if="isLoyaltyEnabled === false"
                class="text-right text-danger"
              >
                {{ $t('disable') }}
              </td>
              <td style="width:5%" class="text-right" @click="statusPopup">
                <CSwitch
                  v-model="isLoyaltyEnabled"
                  color="success"
                  :value="isLoyaltyEnabled"
                  :checked.sync="isLoyaltyEnabled"
                />
              </td>
            </tr>
          </table>

          <div v-if="edit === true">
            <p class="text-danger text-left font-weight-bold">
              {{ validateAlert }}
            </p>
            <CRow>
              <CCol md="12">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label text-left text-dark">
                    Channel ID
                  </label>
                  <div class="col-sm-10">
                    <input
                      class="form-control text-dark"
                      v-model="channelID"
                      disabled
                    />
                  </div>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label text-left text-dark">
                    Channel Secret
                  </label>
                  <div class="col-sm-10">
                    <input
                      class="form-control text-dark"
                      v-model="channelSecret"
                      disabled
                    />
                  </div>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="2"></CCol>
              <CCol md="10" v-if="loadingButton === true">
                <CButton
                  block
                  color="info"
                  class="px-4 text-white"
                  v-on:click="editHandlePopup"
                  v-if="isLoyaltyEnabled === true"
                >
                  {{ $t('setupInformation') }}
                </CButton>
              </CCol>
              <CCol md="10" v-else-if="loadingButton === false">
                <CButton block color="success" disabled variant="outline">
                  <CSpinner color="dark" size="sm" />
                </CButton>
              </CCol>
            </CRow>
          </div>

          <div v-if="edit === false">
            <p class="text-danger text-left font-weight-bold">
              {{ validateAlert }}
            </p>
            <CRow>
              <CCol md="12">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label text-left text-dark">
                    Channel ID
                  </label>
                  <div class="col-sm-10">
                    <input
                      class="form-control text-dark"
                      v-model="channelID"
                    />
                  </div>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label text-left text-dark">
                    Channel Secret
                  </label>
                  <div class="col-sm-10">
                    <input
                      class="form-control text-dark"
                      v-model="channelSecret"
                    />
                  </div>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="2"></CCol>
              <CCol md="10" v-if="loadingButton === true">
                <CButton
                  block
                  color="success"
                  class="px-4"
                  variant="outline"
                  v-on:click="registerReward"
                >
                  {{ $t('saveInformation') }}
                </CButton>
              </CCol>
              <CCol md="10" v-else-if="loadingButton === false">
                <CButton block color="success" disabled variant="outline">
                  <CSpinner color="dark" size="sm" /> {{ $t('checkingData') }}
                </CButton>
              </CCol>
            </CRow>
          </div>
        </CCol>
      </CRow>
      <hr />
      <h5 class="text-dark">
        {{ $t('shopCanSetUpLoyatyPointAtMenuSetting') }}
      </h5>
    </CCard>
    <CRow>
      <CModal
        :show.sync="popupModal"
        color="success"
        :title="$t('saveInformation')"
        :footer="footer"
        centered
      >
        <h5 class="text-center text-dark">
          {{ $t('confirmSaveData') }}
        </h5>
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="4" v-if="loadingButton === true">
              <CButton color="success" v-on:click="setWebhookEndPoint" block>
                {{ $t('confirm') }}
              </CButton>
            </CCol>
            <CCol col="4" v-else-if="loadingButton === false">
              <CButton color="success" block disabled>
                <CSpinner color="white" size="sm" />
                {{ $t('confirm') }}
              </CButton>
            </CCol>
            <CCol col="4">
              <CButton color="dark" @click="cancel()" block>
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
    <CRow>
      <CModal
        :show.sync="enabledPopup"
        :footer="footer"
        color="danger"
        :title="$t('disable')"
        centered
        class="text-center text-dark"
      >
        <h5>{{ $t('confirmToDisableCrm') }}</h5>
        <p>
          {{ $t('confirmToDisableCrmDialog') }}
        </p>

        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="4">
              <CButton
                color="danger"
                v-on:click="registerReward"
                block
                v-if="loadingButton === true"
              >
                {{ $t('disable') }}
              </CButton>
              <CButton
                color="danger"
                block
                v-else-if="loadingButton === false"
                disabled
              >
                <CSpinner color="white" size="sm" />
                {{ $t('disable') }}
              </CButton>
            </CCol>

            <CCol col="4">
              <CButton color="dark" @click="cancel()" block>
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import service from '@/services/service'
import pos from '@/services/pos'
import crm from '@/services/crm'
import { mapGetters } from 'vuex'
import NoShop from '@/containers/NoShop'

export default {
  components: { NoShop },
  data() {
    return {
      loadingButton: true,
      alert: '',
      validateAlert: '',
      channelID: '',
      channelSecret: '',
      popupModal: false,
      footer: '',
      edit: '',
      isLoyaltyEnabled: false,
      isMenuEnabled: false,
      enabledPopup: false,
      message: '',
      showtime: 2,
    }
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    statusPopup() {
      if (this.isLoyaltyEnabled === true) {
        this.enabledPopup = true
        this.message = ''
      } else if (this.isLoyaltyEnabled === false) {
        this.edit = false
        this.message = this.$i18n.t('activeMembershipWithSilom')
      }
    },
    editHandlePopup() {
      //true : แสดงปุ่มแก้ไขข้อมูล - false : แสดงปุ่มบันทึกข้อมูล
      this.edit = false
      this.alert = ''
    },
    cancel() {
      if (this.isLoyaltyEnabled === false) {
        this.isLoyaltyEnabled = true
      }
      this.popupModal = false
      this.enabledPopup = false
    },
    getDetail() {
      const uid = this.uid

      service
        .get('/shop/v1.0/getbyuser/' + uid)
        .then((res) => {
          let shops = res.data.data

          if (shops[0].channelID !== undefined) {
            this.channelID = shops[0].channelID
          } else {
            this.channelID = ''
          }

          if (shops[0].channelSecret !== undefined) {
            this.channelSecret = shops[0].channelSecret
          } else {
            this.channelSecret = ''
          }

          if (shops[0].isLoyaltyEnabled !== undefined) {
            this.isLoyaltyEnabled = shops[0].isLoyaltyEnabled
          } else {
            this.isLoyaltyEnabled = false
          }

          if (shops[0].isMenuEnabled !== undefined) {
            this.isMenuEnabled = shops[0].isMenuEnabled
          } else {
            this.isMenuEnabled = false
          }

          if (this.channelID !== '' && this.channelSecret !== '') {
            this.edit = true
          } else {
            this.edit = false
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    registerReward() {
      this.loadingButton = false
      const uid = this.uid
      const data = {
        channelID: this.channelID,
        channelSecret: this.channelSecret,
        isLoyaltyEnabled: this.isLoyaltyEnabled,
        isMenuEnabled: this.isMenuEnabled,
      }

      if (this.channelID === '' || this.channelSecret === '') {
        this.validateAlert = this.$i18n.t('validateInput')
        this.popupModal = false
        this.loadingButton = true
      } else {
        pos({
          method: 'post',
          url: 'api/v1.0/' + uid + '/Shop/register/reward',
          data: data,
        })
          .then((response) => {
            this.generateRichMenu()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    generateRichMenu() {
      const uid = this.uid
      const channelID = this.channelID
      const channelSecret = this.channelSecret
      const isLoyaltyEnabled = this.isLoyaltyEnabled
      const isMenuEnabled = this.isMenuEnabled

      const data = {
        uid: uid,
        channelID: channelID,
        channelSecret: channelSecret,
        isLoyaltyEnabled: isLoyaltyEnabled,
        isMenuEnabled: isMenuEnabled,
      }
      crm({
        method: 'post',
        url: '/api/v1.0/' + uid + '/rich-menu/generate',
        data: data,
      })
        .then((response) => {
          console.log('response.data', response.data)
          if (response.data.error.code === 0) {
            this.popupModal = true
            this.validateAlert = ''
            if (isLoyaltyEnabled === false) {
              this.popupModal = false
              this.loadingButton = true
              this.alert = this.$i18n.t('saveSuccess')
              this.enabledPopup = false
            } else {
              this.loadingButton = true
            }
          } else {
            this.popupModal = false
            this.validateAlert = this.$i18n.t('validateInfomation')
            this.loadingButton = true
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async setWebhookEndPoint() {
      const uid = this.uid
      const channelID = this.channelID
      const channelSecret = this.channelSecret
      const endpoint = process.env.VUE_APP_CRM_API_URL + '/api/v1.0/hook/' + uid

      const data = {
        channelID: channelID,
        channelSecret: channelSecret,
        endpoint: endpoint,
      }

      // Dismiss popup confirm
      this.popupModal = false
      this.loadingButton = false
      try {
        await crm({method: 'post', url: '/api/v1.0/setwebhook/' + uid, data: data, })
        
        this.alert = this.$i18n.t('saveSuccess')
        this.message = ''
      } catch (error) {
        console.log(error)
      }

      this.loadingButton = true 
      this.edit = true
    },
  },
}
</script>

<style scoped>
.underline {
  text-decoration: none;
}
.txt:hover {
    text-decoration: underline;
}
</style>
