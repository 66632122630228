<template>
  <CRow>
    <CCol col="12" class="text-black">
      <CCard>
        <CCardBody>
          <CRow>
            <CCol md="12" class="text-center">
              <h2 class="font-weight-normal text-dark">{{ $t('contact') }}</h2>
              <h6 class="text-description">
                {{ $t('contactDialog') }}
              </h6>
            </CCol>
          </CRow>
          <hr />
          <CAlert v-if="alert != ''" color="success" :show="showtime">
            {{ alert }}
          </CAlert>
          <p class="text-danger">
            <strong>{{ validateAlert }}</strong>
          </p>
          <table style="width:100%" v-if="edit === true" aria-describedby="">
            <tr class="h5 text-left">
              <th scope="col" class="font-weight-normal text-dark">{{ $t('tel') }}</th>
              <td class="font-weight-normal text-dark">
                <input
                  class="form-control text-dark"
                  :placeholder="$t('inputPhoneNumber')"
                  v-model="tel"
                  disabled
                />
              </td>
            </tr>
            <br />
            <tr class="h5 text-left">
              <td class="font-weight-normal text-dark">{{ $t('website') }}</td>
              <td class="font-weight-normal text-dark">
                <input
                  class="form-control text-text-dark"
                  :placeholder="$t('example')+ ' https://silompos.com'"
                  v-model="website"
                  disabled
                />
              </td>
            </tr>
            <br />
            <tr class="h5 text-left">
              <td class="font-weight-normal text-dark">Facebook</td>
              <td class="font-weight-normal text-dark">
                <input
                  class="form-control text-dark"
                  :placeholder=" $t('example')+ 'https://facebook.com/silompos'"
                  v-model="facebook"
                  disabled
                />
              </td>
            </tr>
            <br />
            <tr class="h5 text-left">
              <td class="font-weight-normal text-dark">Line@</td>
              <td class="font-weight-normal text-dark">
                <input
                  class="form-control text-black"
                  :placeholder="$t('example')+' @silompos'"
                  v-model="lineadd"
                  disabled
                />
              </td>
            </tr>
            <br />
          </table>

          <table style="width:100%" v-else aria-describedby="">
            <tr class="h5 text-left">
              <th scope="col" class="font-weight-normal text-dark">{{ $t('tel') }}</th>
              <td class="font-weight-normal text-dark">
                <input
                  class="form-control text-dark"
                  :placeholder="$t('inputPhoneNumber')"
                  v-model="tel"
                />
              </td>
            </tr>
            <br />
            <tr class="h5 text-left">
              <td class="font-weight-normal text-dark">{{ $t('website') }}</td>
              <td class="font-weight-normal text-dark">
                <input
                  class="form-control text-dark"
                  :placeholder="$t('example')+ ' https://silompos.com'"
                  v-model="website"
                />
              </td>
            </tr>
            <br />
            <tr class="h5 text-left">
              <td class="font-weight-normal text-dark">Facebook</td>
              <td class="font-weight-normal text-dark">
                <input
                  class="form-control text-dark"
                  :placeholder=" $t('example') + ' https://facebook.com/silompos'"
                  v-model="facebook"
                />
              </td>
            </tr>
            <br />
            <tr class="h5 text-left">
              <td class="font-weight-normal text-dark">Line@</td>
              <td class="font-weight-normal text-dark">
                <input
                  class="form-control text-dark"
                  :placeholder="$t('example')+' @silompos'"
                  v-model="lineadd"
                />
              </td>
            </tr>
            <br />
          </table>

          <hr />

          <br />
          <CButton
            v-if="edit === true"
            @click="edit = false"
            color="info"
            class="px-4 text-white"
            block
          >
            {{$t('edit')}}
          </CButton>
          <CButton v-else @click="validate" block color="success" class="px-4">
            {{$t('save')}}
          </CButton>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal
      :show.sync="popupModal"
      color="success"
      :title="$t('saveInformation')"
      centered
      :footer="footer"
    >
      <h4 class="text-center text-dark">{{ $t('confirmSaveData') }}</h4>
      <template #footer-wrapper>
        <CRow class="justify-content-center col-md-12">
          <CCol col="4">
            <CButton
              block
              color="success"
              v-if="loadingButton === true"
              @click="updateContact()"
            >
              {{ $t('confirm') }}
            </CButton>
            <CButton
              block
              color="success"
              v-else-if="loadingButton === false"
              disabled
            >
              <CSpinner color="white" size="sm" />  {{ $t('confirm') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="dark" @click="popupModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import crm from '@/services/crm'

export default {
  data() {
    return {
      popupModal: false,
      loadingButton: true,
      footer: '',
      tel: '',
      website: '',
      facebook: '',
      lineadd: '',
      validateAlert: '',
      alert: '',
      showtime: 2,
      edit: '',
      objectId: '',
    }
  },
  computed: {
    ...mapGetters(['shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
  },
  mounted() {
    this.contactDetail()
  },
  methods: {
    validate() {
      if (
        this.tel === '' &&
        this.website === '' &&
        this.facebook === '' &&
        this.lineadd === ''
      ) {
        this.validateAlert = this.$i18n.t('validInputContact')
        this.popupModal = false
      } else {
        this.validateAlert = ''
        this.popupModal = true
      }
    },
    contactDetail() {
      const uid = this.uid

      crm({
        method: 'get',
        url: 'api/v1.0/' + uid + '/contact/getbyuid',
      })
        .then((response) => {
          let documents = response.data.data.documents
          if (documents !== null) {
            this.objectId = documents.objectId
            this.edit = true
            this.tel = documents.tel
            this.website = documents.website
            this.facebook = documents.facebook
            this.lineadd = documents.lineadd
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    updateContact() {
      this.loadingButton = false
      const uid = this.uid
      const data = {
        objectId: this.objectId,
        uid: this.uid,
        shops: this.shops,
        tel: this.tel,
        facebook: this.facebook,
        website: this.website,
        lineadd: this.lineadd,
      }

      crm({
        method: 'post',
        url: 'api/v1.0/' + uid + '/contact/save',
        data: data,
      })
        .then((response) => {
          this.loadingButton = true
          this.alert = this.$i18n.t('confirmSaveData')
          this.popupModal = false
          this.edit = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
